nav {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 7vh;
    background-color: var(--primary-color);
    z-index: 2;
}

nav .logo {
    grid-column: 1;
}

.nav__menu {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr 1fr 1fr 1fr;
    grid-gap: .5em;
    padding-right: 1.5em;
}

.nav__item {
    text-align: center;
    align-self: center;
    padding: .3em;
    list-style: none;
}

.nav__item a {
    font-family: var(--primary-font-family);
    font-size: 1.1rem;
    letter-spacing: .2em;
    text-decoration: none;
    color: black;
    padding: .2em 1em;
    border-bottom: 2px solid transparent;
    transition: border-bottom .8s;
}

.nav__item a:hover {
    border-bottom: 2px solid black;
}

.nav__icon {
    list-style: none;
    text-align: center;
    align-self: center;
    padding: .5em .2em .2em .2em;
}

.nav__icon a {
    color: black;
    padding: .2em;
}

.nav__icon--hidden {
    display: none;
    cursor: pointer;
}

@media (max-width: 1180px) {
    .nav__item {
        display: none;
    }

    .nav__menu {
        display: flex;
        justify-content: flex-end;
    }

    .nav__icon {
        margin: 0 .6em;
    }

    .nav__icon--hidden {
        display: block;
    }
}

@media (max-width: 360px) {
    .nav__item a {
        font-size: .9rem;
    }
}