.courses__section {
    min-height: 40vh;
}

.courses__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, .6fr));
    grid-auto-rows: 500px;
    grid-gap: 5em;
    min-height: 60vh;
    margin: 10vh 0;
    justify-content: center;
    list-style: none;
}

.courses__no-result-message {
    margin: 8vh 0 0 0;
}

.courses-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 10vh;
    background-color: var(--primary-color);
    margin-bottom: 10vh;
    border-radius: 6px;
}

.courses-contact__header {
    margin-bottom: 3vh;
    padding: .3em 0 0 .8em;
}

.courses-contact svg {
    margin: .5em 2em .5em 2em;
    color: black;
}

.courses-contact__description, .courses__no-result-message {
    font-family: var(--primary-font-family);
    font-size: 2em;
    letter-spacing: .08em;
    font-weight: 300;
    padding: 0 0 0 1em;
}

.courses-contact__link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.courses-contact__link p {
    font-family: var(--primary-font-family);
    font-size: 1.2em;
    letter-spacing: .3em;
}

@media (max-width: 20000px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(300px, .3fr));
        grid-auto-rows: 480px;
    } 
}

@media (max-width: 1600px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(300px, .3fr));
    } 
}

@media (max-width: 1450px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(300px, .4fr));
    }
}

@media (max-width: 1360px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(300px, .5fr));
        grid-auto-rows: 460px;
    }
}

@media (max-width: 1260px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(300px, .35fr));
        grid-auto-rows: 440px;
    }
}

@media (max-width: 1160px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(290px, .35fr));
        grid-auto-rows: 430px;
    }
}

@media (max-width: 800px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(280px, .35fr));
        grid-auto-rows: 390px;
        grid-gap: 5em;
    }
}

@media (max-width: 550px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(250px, .5fr));
        grid-auto-rows: 370px;
        grid-gap: 5em;
    }
}

@media (max-width: 470px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(220px, .6fr));
        grid-auto-rows: 350px;
        grid-gap: 5em;
    }
}

@media (max-width: 360px) {
    .courses__list {
        grid-template-columns: repeat(auto-fill, minmax(200px, .7fr));
        grid-auto-rows: 320px;
        grid-gap: 5em;
    }
}