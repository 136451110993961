footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary-color);
    min-height: 6vh;
    width: 100vW;
}

footer div {
    display: flex;
    justify-content: space-around;
}

footer div a {
    color: black;
    text-decoration: none;
}

footer div a i {
    font-size: 1.9em;
    margin: .5em 0;
}

footer div p {
    color: black;
    padding: .4rem;
}