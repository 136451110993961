.course-cart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--secondary-font-family);
    padding: .9em;
    background-size: cover;
    background-color: rgb(172, 172, 172);
    background-blend-mode: screen;
    border-radius: 6px;
    box-shadow: 9px 9px 10px 3px #acacac;
}

.course-cart__name {
    font-family: var(--primary-font-family);
    font-size: 1.7em;
    letter-spacing: .03em;
    text-shadow: 7px 7px 20px var(--secondary-color);
}

.course-cart__level {
    font-size: 1.4em;
    font-weight: 600;
    letter-spacing: .07em;
    text-shadow: 7px 7px 20px var(--secondary-color);
    margin-top: .4em;
}

.course-cart__duration, .course-cart__date, .course-cart__place, .course-cart__teacher {
    display: flex;
    padding: .1em;
    margin-bottom: .5em;
}

.course-cart__duration p, .course-cart__date p, .course-cart__place p, .course-cart__teacher p {
    display: inline-block;
    align-self: center;
    font-size: 1.1em;
    letter-spacing: .1em;
    margin-left: .5em;
    font-weight: bold;
    text-shadow: 7px 7px 20px var(--secondary-color);
}

.course-cart__buy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    margin-top: 1.3em;
}

.course-cart__buy button {
    font-size: 1.2em;
    padding: .5em 1.5em;
    border: 1px solid black;
    transition: background-color .8s;
}

.course-cart__buy button:hover {
    background-color: white
}

.course-cart__buy p {
    display: inline-block;
    font-size: 1.7em;
    font-weight: bold;
}