.main {
    letter-spacing: .15em;
    margin: 5vh auto 0 auto;
}

.main__header {
    font-family: var(--primary-font-family);
    font-size: 2.5em;
    letter-spacing: .08em;
    margin-bottom: 3vh;
}

.main__description {
    font-family: var(--primary-font-family);
    font-size: 2em;
    letter-spacing: .08em;
    font-weight: 300;
}

.main__tagline {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../backgrounds/back_2.jpg');
    background-color: var(--secondary-color);
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 80vh;
    width: 100%; 
}

.main__tagline p {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
    line-height: 1.6em;
    color: rgb(200, 200, 200);
    text-shadow: 7px 7px 20px black;
    margin: .5em 0;
    width: 60%;
}

.main__tagline p:last-child {
    display: block;
    text-align: right;
    font-size: 1.5em;
}

.main__advantages {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(400px, 2fr));
    grid-gap: 4em;
    width: 95%;
    margin: 15vh auto;
}

.main__advantages--first, .main__advantages--second {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, .7fr));
    grid-gap: 4em;
    justify-content: center;
}

.main__advantages--first div, .main__advantages--second div {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 2em;
}

.main__advantages i {
    font-size: 5em;
    margin: 8% 0;
}

.main__advantages svg {
    position: absolute;
    top: 5%;
    left: 40%;
}

.main__advantages .main__header, .main__advantages .main__description {
    padding: 0 2em;
}

.main__instructors {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
    width: 80%;
    margin: 8vh auto;
}

.main__instructors-list h1 {
    font-family: var(--primary-font-family);
    font-size: 3rem;
    letter-spacing: .15em;
    padding: .5em;
}

.main__instructors-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, .6fr));
    grid-auto-rows: minmax(320px, 700px);
    grid-gap: 5em;
    justify-content: center;
    margin: 7vh;
    list-style: none;
}

.main__instructor-item {
    background-color: var(--primary-color);
    box-shadow: 9px 9px 10px 3px #acacac;
    border-radius: 6px;
}

.main__instructor-item-container {
    display: grid;
    grid-template-columns: 35% 10% 55%;
    grid-template-rows: auto;
}

.main__instructor-img {
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 1;
    filter: grayscale(100%);
    border-radius: 6px 0 0 0;
}

.main__instructor-name {
    grid-column: 2 / -1;
    grid-row: 1 / 1;
    font-size: .5rem;
    color: rgb(230, 230, 230);
    text-shadow: 7px 7px 20px black;
    z-index: 1;
}

.main__instructor-testimonial {
    font-family: 'Playfair Display', serif;
    font-size: 1.7em;
    line-height: 1.5em;
    letter-spacing: .05em;
    word-spacing: .15em;
    margin-top: 1em;
    padding: .5em;
}

.main__students {
    grid-column: 1 / -1;
    grid-row: 5 / 6;
    width: 80%;
    margin: 8vh auto;
}

.main__learn-more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.main__learn-more svg {
    margin: .5em 2em .5em 2em;
    color: black;
}

.main__learn-more p {
    font-family: var(--primary-font-family);
    font-size: 1.2em;
    letter-spacing: .3em;
    color: black;
}

.main__tagline--bottom {
    grid-column: 1 / -1;
    grid-row: 6 / 7;
    margin: 8vh auto 0;
    background-image: url('../backgrounds/back_7.jpg');
    background-color: var(--secondary-color);
    background-blend-mode: screen;
    min-height: 45vh;
}

.main__tagline--bottom p {
    color: black;
    text-shadow: 7px 7px 20px var(--secondary-color);
}

@media (max-width: 1450px) {
    .main__instructor-testimonial {
        font-size: 1.7em;
    }
    .main__instructors-list {
        grid-auto-rows: minmax(320px, 660px); 
    }
}

@media (max-width: 1360px) {
    .main__instructors-list {
        grid-auto-rows: minmax(320px, 620px); 
    }
}

@media (max-width: 1260px) {
    .main__instructor-testimonial {
        font-size: 1.7em;
    }
    .main__instructors-list {
        grid-auto-rows: minmax(320px, 580px); 
    }
}

@media (max-width: 1160px) {
    .main__instructor-testimonial {
        font-size: 1.8em;
    }
    .main__advantages--first, .main__advantages--second {
        grid-template-columns: repeat(auto-fit, minmax(500px, .6fr));
    }
    .main__instructors-list {
        grid-auto-rows: minmax(320px, 560px);
    }
}

@media (max-width: 800px) {
    .main__instructor-testimonial {
        font-size: 1.9em;
    }
    .main__advantages--first, .main__advantages--second {
        grid-template-columns: repeat(auto-fit, minmax(500px, .7fr));
    }
    .main__instructors-list {
        grid-auto-rows: minmax(320px, 530px);
    }
}

@media (max-width: 550px) {
    .main__instructor-testimonial {
        font-size: 2.1em;
    }
    .main__advantages--first, .main__advantages--second {
        grid-template-columns: repeat(auto-fit, minmax(440px, .7fr));
    }
    .main__instructors-list {
        grid-auto-rows: minmax(300px, 540px);
    }
}

@media (max-width: 470px) {
    .main__instructor-testimonial {
        font-size: 2.1em;
    }
    .main__advantages--first, .main__advantages--second {
        grid-template-columns: repeat(auto-fit, minmax(350px, .7fr));
    }
    .main__instructors-list {
        grid-template-columns: repeat(auto-fill, minmax(300px, .6fr));
        grid-auto-rows: minmax(300px, 470px);
    }
}

@media (max-width: 360px) {
    .main__instructor-testimonial {
        font-size: 2.2em;
    }
    .main__advantages--first, .main__advantages--second {
        grid-template-columns: repeat(auto-fit, minmax(300px, .7fr));
    }
    .main__instructors-list {
        grid-template-columns: repeat(auto-fill, minmax(250px, .6fr));
        grid-auto-rows: minmax(200px, 430px);
    }
}