.cart h1 {
    margin-bottom: 5vh;
}

.cart__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font-family);
    padding-bottom: .5em;
    margin: 1em 0;
    border-bottom: 1px solid black;
    letter-spacing: .02em;
}

.cart__menu p {
    font-size: 1.2em;
}

.cart__menu-remove {
    width: 7%;
    text-align: center;
}

.cart__price {
    width: 14%;
    text-align: center;
    padding: .2em .4em;
}

.cart__list {
    font-family: var(--primary-font-family);
    letter-spacing: .02em;
    word-spacing: .15em;
    font-weight: 300;
    min-height: 30vh;
}

.cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
}

.cart__total-price {
    font-family: var(--primary-font-family);
    margin: 3em 0;
    letter-spacing: .03em;
    font-size: 1.2em;
}

.cart__btn--section {
    display: flex;
    align-content: center;
    justify-content: space-around;
    margin-bottom: 10vh;
}

.cart__btn {
    padding: .35em .45em;
    margin: 0 auto;
    transition: background-color .8s;
}

.cart__btn:hover {
    background-color: white;
}

.cart__btn--large {
    display: flex;
    justify-content: center;
    font-family: var(--primary-font-family);
    width: 35%;
    font-size: 1.2em;
    padding: .5em .03em;
    letter-spacing: .3em;
    color: black;
    border-radius: 6px;
    border: 1px solid black;
    background-color: var(--primary-color);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: background-color .8s;
}

.cart__btn--large:hover {
    background-color: white;
}

.cart__empty-cart-message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10vh;
}

.cart__empty-cart-message p {
    font-family: var(--primary-font-family);
    font-size: 1.2em;
    letter-spacing: .3em;
    color: black;
}

.cart__empty-cart-message svg {
    margin: .5em 11em .5em 2em;
    color: black;
}

@media (max-width: 1160px) {
    .cart__btn--large {
        width: 37%;
    }
}

@media (max-width: 800px) {
    .cart__btn--large {
        width: 40%;
    }
}

@media (max-width: 700px) {
    .cart__btn--large {
        width: 43%;
    }
}

@media (max-width: 470px) {
    .cart__btn--large {
        width: 45%;
    }
}

@media (max-width: 360px) {
    .cart__btn--large {
        width: 48%;
    }
}