.contact__details {
    display: flex;
    flex-direction: column;
    margin-bottom: 4em;
}

.contact__details .logo {
    font-size: 1.5em;
    margin: 0 0 .5em .8em;
}

.contact__address {
    margin-top: 1em;
}

.contact__phone, .contact__address, .contact__mail {
    display: flex;
    align-items: center;
    margin: .35em 0;
}

.contact__phone p, .contact__address p, .contact__mail p {
    display: inline-block;
    font-size: 1.5em;
    margin-left: .5em;
}

.contact__phone svg, .contact__address svg, .contact__mail svg {
    display: inline-block;
}