.contact {
    font-family: var(--primary-font-family);
}

.contact__section-intro {
    margin-bottom: 3em;
}

.contact__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, .8fr));
    grid-template-rows: repeat(auto-fill, minmax(270px, 2fr));
    justify-content: center;
    grid-auto-rows: 270px;
    grid-gap: 1em;
    margin: 3vh 1em 10vh;
}

.contact__form-section {
    margin-top: 3em;
}

.contact__form {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 3em 0;
}

.contact__form label {
    font-size: 1.2em;
}

.contact__form input {
    font-family: var(--primary-font-family);
    font-weight: 300;
    letter-spacing: .07em;
    width: 50%;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 1.5em;
}

.contact__form textarea {
    font-family: var(--primary-font-family);
    font-weight: 300;
    letter-spacing: .07em;
    width: 100%;
    outline: none;
    border: none;
    border: 1px solid black;
    margin-top: 1em;
}

.contact__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    font-size: 1.2em;
    padding: .5em .3em;
    margin: 1em 0 .5em auto;
    font-family: var(--primary-font-family);
    letter-spacing: .3em;
    color: black;
    border: 1px solid black;
    border-radius: 6px;
    background-color: var(--primary-color);
    outline: none;
    cursor: pointer;
    transition: background-color .8s;
}

.contact__btn:hover {
    background-color: white;
}

.contact__btn svg {
    margin-right: .7em;
}

.contact__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.contact__circle svg {
    position: absolute;
    bottom: 10%;
    right: 0;
}

.contact__circle p {
    margin: 3em 1em 3em 0;
}

@media (max-width: 800px) {
    .contact__container {
        grid-template-columns: repeat(auto-fit, minmax(450px, .8fr));
        grid-template-rows: repeat(auto-fill, minmax(130px, 2fr));
    }
    .contact__form input {
        width: 60%;
    }
}

@media (max-width: 550px) {
    .contact__container {
        grid-template-columns: repeat(auto-fit, minmax(390px, .8fr));
        grid-template-rows: repeat(auto-fill, minmax(130px, 2fr));
        grid-auto-rows: 240px;
    }
}

@media (max-width: 470px) {
    .contact__container {
        grid-template-columns: repeat(auto-fit, minmax(320px, .8fr));
        grid-template-rows: repeat(auto-fill, minmax(100px, 2fr));
        grid-auto-rows: 200px;
    }
    .contact__form input {
        width: 70%;
    }
}

@media (max-width: 360px) {
    .contact__container {
        grid-template-columns: repeat(auto-fit, minmax(290px, .8fr));
    }
}

@media (max-width: 300px) {
    .contact__container {
        grid-template-columns: repeat(auto-fit, minmax(260px, .8fr));
    }
}