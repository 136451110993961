.checkout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, .8fr));
    grid-column-gap: 2em;
    grid-row-gap: 5em;
    margin-bottom: 10vh;
    justify-content: center;
}

.checkout h1 {
    text-align: center;
}

.checkout__summary {
    margin: 3em auto;
    width: 80%;
}

.checkout__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font-family);
    letter-spacing: .02em;
    padding-bottom: .5em;
    margin: 1em 0;
    border-bottom: 1px solid black;
}

.checkout__item-name {
    width: 63%;
}

.checkout__item p, .checkout__menu p {
    font-size: 1.2em;
}

.checkout__quantity {
    width: 16%;
    padding: .2em .4em;
    text-align: center;;
}

.checkout__price {
    width: 14%;
    text-align: center;
    padding: .2em .4em;
}

.checkout__list {
    font-family: var(--primary-font-family);
    letter-spacing: .02em;
    word-spacing: .15em;
    font-weight: 300;
    min-height: 30vh;
}

.checkout__item, .checkout__menu  {
    display: flex;
    justify-content: space-between;
}

.checkout__total-price {
    text-align: end;
    font-family: var(--primary-font-family);
    font-size: 1.2em;
    padding-right: .4em;
    letter-spacing: .03em;
}

.checkout__btn {
    display: flex;
    justify-content: center;
    font-family: var(--primary-font-family);
    font-size: 1.2em;
    letter-spacing: .3em;
    color: black;
    padding: .5em .03em;
    margin: 0 auto;
    border: 1px solid black;
    width: 40%;
    border-radius: 6px;
    background-color: var(--primary-color);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    transition: background-color .8s;
}

.checkout__btn:hover {
    background-color: white;
}

.checkout__btn a {
    font-size: 1em;
    color: black;
    text-decoration: none;
}

.checkout__form {
    width: 80%;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font-family);
    padding: 2em;
    margin: 3em auto;
    border: 1px solid black;
    border-radius: 6px;
}

.checkout__form label {
    font-size: 1.2em;
}

.checkout__form input {
    font-weight: 300;
    letter-spacing: .07em;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 1.5em;
}

@media (max-width: 550px) {
    .checkout {
        grid-template-columns: repeat(auto-fit, minmax(460px, .8fr));
    }
}

@media (max-width: 470px) {
    .checkout {
        grid-template-columns: repeat(auto-fit, minmax(400px, .8fr));
    }
}

@media (max-width: 360px) {
    .checkout {
        grid-template-columns: repeat(auto-fit, minmax(300px, .8fr));
    }
}