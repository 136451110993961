.courses__filters {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: var(--secondary-font-family);
    min-height: 10vh;
    background-color: var(--primary-color);
    margin-top: 10vh;
    padding: .8em;
    border-radius: 6px;
}

.courses__filters label {
    font-size: 1.5em;
}

.courses__filters select {
    margin-left: 1em;
    outline: none;
}

.courses__filters option {
    font-size: 1.2em;
    font-family: var(--secondary-font-family);
}

@media (max-width: 800px) {
    .courses__filters {
        display: flex;
        align-items: center;
        justify-content: space-around;
        min-height: 10vh;
        margin-top: 10vh;
    }
}

@media (max-width: 690px) {
    .courses__filters svg {
        display: none;
    }
}