:root {
    --primary-color: #666;
    --secondary-color: rgb(105, 105, 114);
    --primary-font-family: 'Oswald', sans-serif;
    --secondary-font-family: 'Dosis', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
}

section {
    min-height: 100vh;
    width: 95%;
    margin: 15vh auto 0;
    padding: 0 5em;
    position: relative;
}

section h1 {
    font-family: var(--primary-font-family);
    font-size: 2.5em;
    margin-bottom: 3vh;
    letter-spacing: .08em;
}

.logo {
    letter-spacing: .1em;
    grid-column: 1 / -1;
    font-family: 'Pacifico', cursive;
    font-size: 1.7rem;
    padding-left: .5em;
    letter-spacing: .1em;
    outline: none;
}

.logo a {
    font-size: 1.7rem;
    padding: .3em 0 0 .5em;
    text-decoration: none;
    color: black;
    outline: none;
}

.section-circle {
    position: absolute;
    top: 0;
    left: 4%;
}

.section-intro {
    font-family: var(--primary-font-family);
    font-size: 2em;
    letter-spacing: .02em;
    word-spacing: .15em;
    font-weight: 300;
    padding: 0 .5em;
}

.btn {
    font-family: var(--primary-font-family);
    letter-spacing: .3em;
    color: black;
    border-radius: 6px;
    border: none;
    background-color: var(--primary-color);
    outline: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
}

@media (max-width: 1450px) {
    * {
        font-size: 15px;
    }
}

@media (max-width: 1360px) {
    * {
        font-size: 14px;
    }
}

@media (max-width: 1260px) {
    * {
        font-size: 13px;
    }
}

@media (max-width: 1160px) {
    * {
        font-size: 12px;
    }
}

@media (max-width: 800px) {
    * {
        font-size: 11px;
    }
    section {
        width: 97%;
        padding: 0 4em;
    }
}

@media (max-width: 550px) {
    * {
        font-size: 10px;
    }
    section {
        width: 100%;
        padding: 0 3em;
    }
}

@media (max-width: 470px) {
    * {
        font-size: 9px;
    }
}

@media (max-width: 360px) {
    * {
        font-size: 8px;
    }
}

@media (max-width: 300px) {
    * {
        font-size: 6.5px;
    }
}