.menu {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 8vh;
    left: 0;
    background-color: var(--primary-color);
    width: 100%;
    height: 40vh;
    z-index: 99;
}

.menu__list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 60%;
}

.menu__icon {
    text-align: right;
    padding: .5em .2em .2em .2em;
    list-style: none;
    cursor: pointer;
}

.menu__item {
    display: flex;
    justify-content: center;
    padding: .3em;
    list-style: none;
}

.menu__item a {
    font-family: var(--primary-font-family);
    font-size: 1.2rem;
    letter-spacing: .2em;
    text-decoration: none;
    color: black;
    padding: .2em 1em;
    border-bottom: 2px solid transparent;
    transition: border-bottom .8s;
}

.menu__item a:hover {
    border-bottom: 2px solid black;
}