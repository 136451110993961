.cart__item-name {
    width: 63%;
}

.cart__item p {
    font-size: 1.2em;
}

.cart__quantity {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 16%;
    padding: .2em .4em;
}

.cart__quantity p {
    padding: .2em .4em;
}

.cart__price {
    width: 14%;
    text-align: center;
    padding: .2em .4em;
}

.cart__menu-remove {
    width: 7%;
    text-align: center;
}

.cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0;
}

@media (max-width: 700px) {
    .cart__quantity .cart__btn, .cart__menu-remove .cart__btn {
        font-size: .5em;
    }
}

@media (max-width: 360px) {
    .cart__quantity .cart__btn, .cart__menu-remove .cart__btn {
        font-size: .1em;
    }
}