.login {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, .8fr));
    grid-column-gap: 2em;
    grid-row-gap: 5em;
    margin-bottom: 10vh;
    justify-content: center;
}

.login__section h1 {
    text-align: center;
}

.login__form {
    width: 80%;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font-family);
    padding: 2em;
    margin: 3em auto;
    border: 1px solid black;
    border-radius: 6px;
}

.login__form input {
    font-weight: 300;
    letter-spacing: .07em;
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    margin-bottom: 1.5em;
    outline: none;
}

.login__form label {
    font-size: 1.2em;
}

.login__form a {
    text-align: end;
    text-decoration: none;
    color: black;
}

.login__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: var(--primary-font-family);
    font-size: 1.2em;
    color: black;
    letter-spacing: .3em;
    padding: .5em .3em;
    margin: 1em 0 .5em auto;
    border-radius: 6px;
    border: 1px solid black;
    background-color: var(--primary-color);
    outline: none;
    cursor: pointer;
    transition: background-color .8s;
}

.login__btn:hover {
    background-color: white;
}

@media (max-width: 550px) {
    .login {
        grid-template-columns: repeat(auto-fit, minmax(460px, .7fr));
        grid-row-gap: .5em;
    }
}

@media (max-width: 470px) {
    .login {
        grid-template-columns: repeat(auto-fit, minmax(400px, .7fr));
        grid-row-gap: .5em;
        margin-bottom: 2vh;
    }
}

@media (max-width: 360px) {
    .login {
        grid-template-columns: repeat(auto-fit, minmax(300px, .7fr));
        grid-row-gap: .1em;
        margin-bottom: .5vh;
    }
}